<template>
  <div>
    <b-input-group>
      <b-input-group-prepend v-if="showPrepend">
        <b-input-group-text>
          <span class="fal fa-donate" />
        </b-input-group-text>
      </b-input-group-prepend>
      <b-form-input
        ref="inputRef"
        type="text"
        :placeholder="placeholder || defaultPlaceholder"
        :state="validationState"
        :disabled="disabled"
        v-model="formattedValue"
        @change="adjustAmount"
      />
      <b-form-invalid-feedback :state="validationState">
        <b-alert show variant="warning" class="mt-2 mb-1">
          <p>{{ errorMessage }}</p>
          <!--
            TODO: Readd this. It resets the value correctly but display in currency input does not update
            <p>
              <a class="alert-link pointer h6" @click="adjustAmount">
                <translate>Adjust amount</translate>
              </a>
            </p>
          -->
        </b-alert>
      </b-form-invalid-feedback>
    </b-input-group>
    <b-alert :show="valueAdjusted" class="my-2">
      <translate>Your donation amount has been automatically adjusted.</translate>
      <translate :translate-params="{ currencyValue: centsToCurrency(stepSize * 100) }">
        This was done because donation amounts are only possible in steps of %{ currencyValue }
      </translate>
    </b-alert>
  </div>
</template>

<script lang="ts">
import { computed, ref, watch } from 'vue'
import { useCurrencyInput } from 'vue-currency-input'
import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'

import LocaleMixin from '@/mixins/LocaleMixin'

@Component({
  name: 'currency-input',
  setup(props) {
    const valueAdjusted = ref(false)
    let timer = null
    const options = computed(() => ({
      currency: props.currency,
      locale: navigator.language,
    }))
    const { inputRef, formattedValue, setOptions, setValue } = useCurrencyInput(options.value)
    setOptions({
      currency: props.currency,
    })
    watch(
      () => props.value,
      (value) => {
        if (Math.abs((Math.round(value * 100) % Math.round(props.stepSize * 100)) / 100) < 1e-10) {
          clearTimeout(timer)
          setValue(value)
        } else {
          const factor = Math.floor(Math.round(value / props.stepSize))
          const adjustedValue = factor * props.stepSize
          clearTimeout(timer)
          timer = setTimeout(() => {
            setValue(adjustedValue)
          }, 200)
          valueAdjusted.value = true
        }
      }
    )
    return { inputRef, valueAdjusted, formattedValue }
  },
})
export default class CurrencyInput extends Mixins(LocaleMixin) {
  @Ref() inputRef!: object
  @Prop() value!: number
  @Prop() currency!: string
  @Prop({ default: 0.01 }) stepSize!: number
  @Prop({ default: null }) placeholder: string
  @Prop({ default: false }) disabled: boolean
  @Prop({ default: null }) limits: { min_in_cents: number; max_in_cents: number }
  @Prop({ default: true }) showPrepend!: boolean // Make this a slot if we need it more often

  get valueInCents(): number {
    return Math.round(this.value * 100)
  }

  get errorMessage(): string {
    if (!this.value || !this.limits) {
      return null
    }
    if (this.valueInCents > this.limits.max_in_cents) {
      return this.$gettextInterpolate(
        this.$gettext('The maximum amount you can currently to donate to this project is %{ maxAmount }'),
        { maxAmount: this.centsToCurrency(this.limits.max_in_cents) }
      )
    } else if (this.valueInCents < this.limits.min_in_cents) {
      return this.$gettextInterpolate(
        this.$gettext('The minimum amount you can currently to donate to this project is %{ minAmount }'),
        { minAmount: this.centsToCurrency(this.limits.min_in_cents) }
      )
    }
    return null
  }

  get validationState(): boolean {
    if (!this.limits) {
      return null
    }
    if (this.value) {
      if (this.errorMessage) {
        this.$emit('invalid')
      } else {
        this.$emit('valid')
      }
      return !this.errorMessage
    }
    this.$emit('invalid')
    return null
  }

  adjustAmount(): void {
    if (this.valueInCents > this.limits?.max_in_cents) {
      this.$emit('input', this.limits.max_in_cents / 100)
    } else if (this.valueInCents < this.limits?.min_in_cents) {
      this.$emit('input', this.limits.min_in_cents / 100)
    }
  }

  get defaultPlaceholder(): string {
    return this.$gettext('Donation amount') + ' *'
  }

  created() {
    useCurrencyInput({ currency: this.currency })
  }
}
</script>
