import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { TGenericObject } from '@/types/base'
import { API_URLS } from '@/utils/helpers'

export interface IPlatformFeatures {
  enable_gewinnsparen?: boolean
  enable_donations?: boolean
  enable_sponsoring?: boolean
  enable_foundation?: boolean
  enable_private_donations?: boolean
  enable_reminder_feature?: boolean
  enable_custom_ids?: boolean
  enable_user_companies?: boolean
  enable_user_projects?: boolean
}

export interface IPlatformProjectSettings {
  milestone_type?: 'basic' | 'detailed' | 'fixed'
}

export interface IPlatformPages {
  terms_of_use_page: string | null
}

export interface IGlobalContent {
  phone_support: string | null
}

export type IPlatformSettings = TGenericObject

interface IPlatformSettingsState {
  features: IPlatformFeatures
  settings: IPlatformSettings
  project_settings: IPlatformProjectSettings
  pages: IPlatformPages
  global_content: IGlobalContent
}

@Module({ dynamic: true, store, name: 'platformSettings' })
class PlatformSettings extends VuexModule implements IPlatformSettingsState {
  features: IPlatformFeatures | null = null
  settings: IPlatformSettings | null = null
  project_settings: IPlatformProjectSettings | null = null
  pages: IPlatformPages | null = null
  global_content: IGlobalContent | null = null

  @Mutation
  SET_PLATFORM_SETTINGS(platformSettings: PlatformSettings) {
    this.features = platformSettings.features
    this.settings = platformSettings.settings
    this.project_settings = platformSettings.project_settings
    this.pages = platformSettings.pages
    this.global_content = platformSettings.global_content
  }

  @Action({ commit: 'SET_PLATFORM_SETTINGS' })
  async fetchPlatformSettings(): Promise<IPlatformSettingsState> {
    const platformFeaturesResponse = await axios
      .get<IPlatformSettingsState>(API_URLS.PLATFORMSETTINGS.SETTINGS)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        throw new Error(error.message)
      })
    return platformFeaturesResponse
  }
}

export const PlatformSettingsModule = getModule(PlatformSettings)
