<template>
  <b-modal id="delete-modal" v-model="isOpenModel" centered v-on="$listeners">
    <template #modal-title>
      <h5 v-translate>Delete: %{ title }</h5>
    </template>

    <template #default>
      <p v-translate>Are you sure that you want to delete <strong>%{ title }</strong>?</p>
      <b-alert :show="!!errorMessage" variant="danger">
        <div class="d-flex">
          <span class="warning-triangle fal fa-exclamation-triangle mr-2" />
          <div>{{ errorMessage }}</div>
        </div>
      </b-alert>
    </template>

    <template #modal-footer="{ close }">
      <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="close()">
        <translate>Close</translate>
      </b-button>
      <b-button id="confirm-delete-btn" variant="danger" @click="confirmDelete">
        <i class="fal fa-trash mr-1" /> <translate>Delete</translate>
      </b-button>
    </template>
  </b-modal>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'delete-modal',
})
export default class DeleteModal extends Vue {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) errorMessage!: string

  get isOpenModel(): boolean {
    return this.value
  }

  set isOpenModel(value: boolean) {
    this.$emit('input', value)
  }

  confirmDelete() {
    this.$emit('confirm-delete')
    this.$root.$emit('bv::hide::modal', 'delete-modal', '#deleteBtn')
  }
}
</script>
