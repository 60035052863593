<template>
  <div class="steps vue">
    <template v-for="(step, i) in value">
      <div
        :key="step.value"
        class="step_info"
        :class="{ active: step.active, disabled: step.disabled || step.locked, success: step.completed }"
        @click="changeStep(step)"
      >
        <a href="#" class="choose-step" data-skip-check="true">
          <div class="step-count" :class="{ locked: step.locked }">
            <i v-if="step.locked" class="fas fa-lock" />
            <span v-else>{{ i + 1 }}</span>
          </div>
          <div class="step-description">{{ step.text }}</div>
        </a>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IStep } from '@/types/base'

@Component({
  name: 'sf-steps',
})
export default class SfSteps extends Vue {
  @Prop({ default: () => [] }) value!: IStep[]

  changeStep(step: IStep, initial?: boolean): void {
    if (step.disabled || step.locked) {
      return
    }
    const otherSteps = this.value.filter((_step) => _step.value !== step.value)
    for (const _step of otherSteps) {
      _step.active = false
    }
    step.active = true
    if (!initial) {
      this.$router.push({ query: { 'v-step': step.value } })
    }
  }

  getActiveStepIndex(): number {
    let activeStepIdx = 0
    for (let i = 0; i < this.value.length; i++) {
      if (this.value[i].active) {
        activeStepIdx = i
        break
      }
    }
    return activeStepIdx
  }

  nextStep(): void {
    const activeStepIdx = this.getActiveStepIndex()
    for (let i = activeStepIdx + 1; i < this.value.length; i++) {
      const nextStep = this.value[i]
      if (nextStep && !(nextStep.disabled || nextStep.locked)) {
        this.changeStep(nextStep)
        break
      }
    }
  }

  previousStep(): void {
    const activeStepIdx = this.getActiveStepIndex()
    for (let i = activeStepIdx - 1; i >= 0; i--) {
      const nextStep = this.value[i]
      if (nextStep && !(nextStep.disabled || nextStep.locked)) {
        this.changeStep(nextStep)
        break
      }
    }
  }

  created(): void {
    if (this.$route.query['v-step']) {
      const validSteps = this.value.filter((step) => step.value === this.$route.query['v-step'])
      if (validSteps.length === 1) {
        this.changeStep(validSteps[0], true)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
