<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p>
          <translate
            >Link the fields in the PDF file with content from the system to enable automatic completion of the
            template.</translate
          >
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-7">
        <FormModule id="mapping-module" :title="$gettext('Link fields with Content')">
          <div v-if="document.pdf_album.files?.length > 0">
            <div v-if="textFields.length > 0" class="row">
              <div v-if="checkboxFields.length > 0" class="col-12">
                <div class="divider">
                  <span v-translate>Text fields</span>
                </div>
              </div>
              <div v-for="field in textFields" :key="field.name" class="col-md-12 col-lg-6">
                <b-form-group
                  :id="'input-group-document-field-' + field.name"
                  :label="'„' + (field.namealt ? field.namealt : field.name) + '“'"
                  :label-for="field.name"
                >
                  <b-form-select v-model="selected[field.name]" :options="textSelectOptions" size="sm" class="mt-3" />
                </b-form-group>
              </div>
            </div>
            <div v-if="checkboxFields.length > 0" class="row">
              <div v-if="textFields.length > 0" class="col-12">
                <div class="divider">
                  <span v-translate>Checkbox fields</span>
                </div>
              </div>
              <div v-for="field in checkboxFields" :key="field.name" class="col-md-12 col-lg-6">
                <b-form-group
                  :id="'input-group-document-field-' + field.name"
                  :label="'„' + (field.namealt ? field.namealt : field.name) + '“'"
                  :label-for="field.name"
                >
                  <b-form-select
                    v-model="selected[field.name]"
                    :options="checkboxSelectOptions"
                    size="sm"
                    class="mt-3"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-12">
              <p><translate>There are no fields to be mapped yet.</translate></p>
              <p><translate>Upload a file to get started!</translate></p>
            </div>
          </div>
        </FormModule>
      </div>
      <div class="col-md-12 col-lg-5">
        <SfAlert variant="info" show>
          <translate
            >If a field has not been detected, check the settings in the PDF and upload the file again in the previous
            step.</translate
          >
        </SfAlert>
      </div>
    </div>
    <div v-if="individualFields.length > 0" class="row">
      <div class="col-md-12 col-lg-7">
        <FormModule id="individual-fields-module" :title="$gettext('Individual fields')">
          <div class="row">
            <div v-for="field in individualFields" :key="field.name" class="col-12">
              <b-form-group
                :id="'input-group-document-field-' + field.name"
                :label="$gettext('Describe') + ' „' + (field.namealt ? field.namealt : field.name) + '“'"
                :label-for="field.name + '-description'"
              >
                <b-form-textarea
                  :id="field.name + '-description'"
                  v-model="individualFieldDescriptions[field.name]"
                  class="mt-3"
                  :placeholder="$gettext('Let users know what this field is for')"
                />
              </b-form-group>
            </div>
          </div>
        </FormModule>
      </div>
      <div class="col-md-12 col-lg-5">
        <SfAlert variant="info" show>
          <translate
            >This description is displayed to the user when creating the document as help for the specific individual
            field.</translate
          >
        </SfAlert>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-button v-translate variant="default" style="margin-right: 1rem" @click="$emit('back')">Back</b-button>
        <b-button
          id="save-mapping-btn"
          class="mr-2"
          variant="base-ci"
          @click="$emit('save', getFieldsWithSelectValues())"
        >
          <b-spinner v-if="saving" variant="black" label="Spinning" small class="mr-2" />
          <translate>Save</translate>
        </b-button>
        <b-button id="finish-edit-btn" variant="base-ci" @click="$emit('finish', getFieldsWithSelectValues())">
          <b-spinner v-if="saving" variant="black" label="Spinning" small class="mr-2" />
          <translate>Save and finish</translate>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import FormModule from '@/components/FormModule.vue'
import SfAlert from '@/components/SfAlert.vue'
import { ISelectGroup, ISelectOption, TGenericObject } from '@/types/base'
import { IDocument, IMappingField } from '@/types/documents'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'map-content-step',
  components: {
    FormModule,
    SfAlert,
  },
})
export default class MapContentStep extends Vue {
  @Prop() value!: TGenericObject
  @Prop() document!: IDocument
  @Prop({ default: false }) saving!: boolean
  selected: TGenericObject = {}
  individualFieldDescriptions: TGenericObject = {}
  individualFieldValues = ['custom.custom_input', 'custom.custom_textarea']
  availableFields: TGenericObject = {}
  additionalFieldSelectOptions: (ISelectOption | ISelectGroup)[] = []

  @Watch('selected')
  onSelectChange(): void {
    this.$emit('input', this.selected)
  }

  getOptions(selectType: string): (ISelectOption | ISelectGroup)[] {
    const baseOptions = [{ value: null, text: this.$gettext('Please choose') }]
    if (this.availableFields[selectType]) {
      const fields = this.availableFields[selectType]
      return [
        ...baseOptions,
        ...Object.keys(fields).map((key) => {
          return { label: key, options: fields[key] }
        }),
      ]
    }
    return baseOptions
  }

  get textSelectOptions(): (ISelectOption | ISelectGroup)[] {
    return this.getOptions('text')
  }

  get checkboxSelectOptions(): (ISelectOption | ISelectGroup)[] {
    return this.getOptions('checkbox')
  }

  get textFields(): IMappingField[] {
    if (!this.document.fields.length) return []
    return this.document.fields.filter((field) => field.type === 'Text')
  }

  get checkboxFields(): IMappingField[] {
    if (!this.document.fields.length) return []
    return this.document.fields.filter((field) => field.type === 'Button')
  }

  get allAvailableDocumentFields(): IMappingField[] {
    return [...this.textFields, ...this.checkboxFields]
  }

  get individualFields(): IMappingField[] {
    return this.textFields.filter((field) => {
      return field.name in this.selected && this.individualFieldValues.includes(this.selected[field.name])
    })
  }

  getFieldsWithSelectValues(): IMappingField[] {
    const tmpFields = this.allAvailableDocumentFields.map((field) => ({ ...field }))
    for (const field of tmpFields) {
      if (this.selected[field.name] !== undefined) {
        field.mapping = this.selected[field.name]
      }
      if (this.individualFieldDescriptions[field.name]) {
        field.description = this.individualFieldDescriptions[field.name]
      }
    }
    return tmpFields
  }

  created(): void {
    for (const field of this.allAvailableDocumentFields) {
      this.$set(this.selected, field.name, field.mapping || null)
      if (this.individualFieldValues.includes(field.mapping)) {
        this.$set(this.individualFieldDescriptions, field.name, field.description || '')
      }
    }

    axios.get(API_URLS.DOCUMENTS.AVAILABLE_FIELDS(this.document.slug)).then((response) => {
      this.availableFields = response.data
    })
  }
}
</script>

<style scoped></style>
