<template>
  <div>
    <div class="row">
      <div class="col-12">
        <p v-translate>Upload the PDF file that you would like to make available as a template.</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-7">
        <FormModule :title="$gettext('Upload File')">
          <div
            id="pdf-uploader"
            class="dropbox"
            :class="{ active: dropActive }"
            style="margin-bottom: 1.5rem"
            @dragenter="dropActive = true"
            @dragleave="dropActive = false"
            @drop="dropActive = false"
          >
            <b-form-file v-model="newFile" class="mt-3 file-input" accept="application/pdf" plain />
            <div v-if="isUploading">
              <p><b-spinner variant="primary" label="Spinning" /></p>
              <p><translate key="uploading">Uploading</translate></p>
            </div>
            <div v-else>
              <p><i class="fal fa-cloud-upload-alt" /></p>
              <p><translate key="drag-info">You can drag and drop your file(s) here,</translate></p>
              <p><translate key="browse-info">or click to browse your files.</translate></p>
            </div>
          </div>
        </FormModule>
      </div>
      <div class="col-md-12 col-lg-5">
        <SfAlert variant="info" show>
          <translate>You can upload one PDF file with a max. file size of 8 MB.</translate>
        </SfAlert>
      </div>
    </div>

    <div v-if="document.pdf_album.files.length > 0" class="row">
      <div class="col-12 col-lg-7">
        <FormModule id="uploaded-pdfs-module" :title="$gettext('Your File')">
          <div v-for="file in document.pdf_album.files" :key="file.slug" class="col-12 col-md-12 col-lg-6 col-xlg-6">
            <div class="thumbnail selectable-thumbnail file">
              <div class="download-wrapper">
                <div class="icon">
                  <i class="fa fa-file" />
                </div>
                <div class="overlay light">
                  <a :href="file.url + '?title=true'" download="" class="overlay no-border">
                    <i class="fa fa-download" />
                  </a>
                </div>
              </div>
              <div
                class="text-left padding-15"
                style="height: 54px; border-top: 1px solid #ececec; word-wrap: anywhere"
              >
                {{ formatDate(file.created_at) }}
              </div>
              <div
                style="
                  border-top: 1px solid #ececec;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                "
              >
                <div class="padding-15" style="word-wrap: anywhere">{{ file.title }}</div>
                <a
                  href="#"
                  class="delete-file padding-15 base-ci"
                  style="color: #ef5350"
                  @click="openDeleteModal(file)"
                >
                  <span class="fa fa-trash-o" />
                </a>
              </div>
            </div>
          </div>
        </FormModule>
      </div>
      <div class="col-md-12 col-lg-5">
        <SfAlert variant="info" show>
          <translate>To change the uploaded file, delete the file and then upload a new file.</translate>
        </SfAlert>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-button v-translate variant="default" style="margin-right: 1rem" @click="$emit('back')">Back</b-button>
        <b-button v-translate variant="base-ci" @click="$emit('next')">Next</b-button>
      </div>
    </div>

    <DeleteModal
      v-model="deleteModalOpen"
      :title="selectedFile ? selectedFile.title.substring(0, 20) + '...' : ''"
      @confirm-delete="deleteFile"
    />
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import DeleteModal from '@/components/modals/DeleteModal.vue'

import FormModule from '@/components/FormModule.vue'
import SfAlert from '@/components/SfAlert.vue'
import ToastMixin from '@/mixins/ToastMixin'
import { IDocument, IPDFFile } from '@/types/documents'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'upload-file-step',
  components: {
    FormModule,
    DeleteModal,
    SfAlert,
  },
})
export default class UploadFileStep extends Mixins(ToastMixin) {
  @Prop({ default: null }) value!: File
  @Prop({ default: '' }) document!: IDocument
  deleteModalOpen = false
  selectedFile: IPDFFile | false = false
  albumContentHTML = ''
  newFile: File = null
  dropActive = false
  isUploading = false

  @Watch('newFile')
  onFileChange(): void {
    this.$emit('input', this.newFile)
    if (this.newFile) {
      this.uploadFile()
    }
  }

  formatDate(dateString: string) {
    return this.$moment(dateString).format('LLL')
  }

  openDeleteModal(file: IPDFFile): void {
    this.selectedFile = file
    this.deleteModalOpen = true
  }

  async uploadFile(): Promise<void> {
    if (this.newFile.type === 'application/pdf') {
      const formData = new FormData()
      formData.append('file', this.newFile)
      this.isUploading = true
      await axios
        .put(API_URLS.DOCUMENTS.UPLOAD_PDF(this.document.slug), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': 'attachment; filename=' + this.newFile.name,
          },
        })
        .then(() => {
          this.$emit('file-uploaded')
        })
        .catch((error) => {
          // There will be an error if the filename contains non-ascii chars before a request is sent.
          if ('response' in error && error.response.status === 403) {
            this.makeToast('danger', this.$gettext('Not allowed'), error.response.data.error)
          } else {
            if ('response' in error && error.response.data && error.response.data.error) {
              this.makeToast('danger', this.$gettext('Error'), error.response.data.error)
            } else {
              this.makeToast('danger', this.$gettext('Error'), `${this.$gettext('Failed to upload file')}\n\n${error}`)
            }
          }
        })
      this.isUploading = false
    } else {
      this.makeToast(
        'danger',
        this.$gettext('Invalid file type'),
        this.$gettext('Only PDF files are allowed to be uploaded')
      )
    }
  }

  deleteFile(): void {
    axios
      .delete(API_URLS.DOCUMENTS.DELETE_PDFS(this.document.slug))
      .then(() => {
        this.$emit('file-deleted')
      })
      .catch(() => {
        this.makeToast('danger', this.$gettext('Error'), this.$gettext('Failed to delete file'))
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dropbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 2px #90caf9 solid; /* the dash box */
  color: #42a5f5;
  background-color: white;
  padding: 1rem;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
  &.active {
    border-width: 5px;
    background-color: #f3f9fe;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      svg {
        font-size: 3rem;
      }
    }
  }
}

.file-input {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: rgba(#6c757d, 0.05);
}
</style>
