<template>
  <div class="url-button-wrapper ml-3">
    <span class="shadow p-2" :class="{ active: showCopied }"><translate>Copied to clipboard</translate></span>
    <b-button size="sm" variant="default" @click="copyToClipboard">
      <span class="fas fa-copy" />
    </b-button>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CopyToClipboard extends Vue {
  @Prop({ required: true }) text!: string
  @Prop({ default: 'Kopieren' }) buttonText!: string

  showCopied = false

  @Emit('copied')
  async copyToClipboard() {
    await navigator.clipboard.writeText(this.text)
    this.showCopied = true
    setTimeout(() => {
      this.showCopied = false
    }, 4000)
  }
}
</script>

<style lang="scss" scoped>
.url-button-wrapper {
  & > span {
    display: none;
    position: absolute;
    text-align: center;
    transform: translateY(-65px) translateX(-30%);
    background-color: #81c784;
    color: white;
    border-radius: 1rem;
    &.active {
      display: inline;
    }
  }
}
</style>
