<template>
  <v-wait for="load data">
    <template slot="waiting">
      <div class="d-flex align-items-center justify-content-center w-100" style="margin: 12rem 5rem">
        <b-spinner variant="primary" label="Spinning" />
      </div>
    </template>

    <GoogleMapLoader
      v-if="googleMapsAccepted && pageSettings?.showMap && locations.length > 0"
      :apiKey="pageSettings.googleMapsApiKey"
      :locations="locations"
      :minMaxBounds="minMaxBounds"
      :pageSettings="pageSettings"
      :isOrganizationExplore="isOrganizationExplore"
      @bounds-changed="onBoundsChanged"
    />
    <MapPlaceholder
      v-if="!googleMapsAccepted && pageSettings?.showMap && locations.length > 0"
      :sparkasse="pageSettings.isSparkasse"
      :platformOperator="pageSettings.platformOperator"
      :platformPrivacyPolicyLink="pageSettings.platformPrivacyPolicyLink"
    />

    <div v-if="exploreHTML" id="additional-content" v-html="exploreHTML" />

    <b-container>
      <b-row v-if="pageSettings?.showCategoriesRow" align-v="center" align-h="center" class="my-3">
        <b-button
          :variant="filters.categories.length === 0 ? 'base-ci' : 'default'"
          :class="filters.categories.length === 0 ? 'active' : ''"
          class="m-1 all-categories-button project-category-filter-button"
          pill
          @click="toggleOffAllCategories"
        >
          <i class="fas fa-th mr-1" />
          <translate>All</translate>
        </b-button>
        <b-button
          v-for="category in categories"
          :id="'project-category_' + category.slug"
          :key="category.slug"
          :variant="filters.categories.includes(category.slug) ? 'base-ci' : 'default'"
          :class="filters.categories.includes(category.slug) ? 'active' : ''"
          class="m-1 project-category-filter-button"
          pill
          @click="toggleCategory(category)"
        >
          <b-img :src="category.icon" style="max-width: 1.5rem" fluid />
          {{ category.title }}
        </b-button>
      </b-row>
    </b-container>

    <div class="project-card-container">
      <b-container>
        <b-row v-if="pageSettings?.showSearchRow" align-v="center" class="justify-content-between">
          <b-col lg="4" md="6" sm="8" class="mb-3">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fal fa-search" />
              </b-input-group-prepend>
              <b-form-input
                id="project-search"
                v-model="filters.searchString"
                :placeholder="$gettext('Project, Location, Organization')"
                debounce="500"
              />
            </b-input-group>
          </b-col>

          <b-col v-if="'hideExpired' in filters" lg="5" md="8" sm="8" class="mb-3 project-filters">
            <b-form-checkbox
              v-if="!pageSettings.isVotingList"
              id="hide-fully-funded-checkbox"
              v-model="filters.hideFullyFunded"
              name="hide-fully-funded-checkbox"
              inline
            >
              <translate>Hide fully funded</translate>
            </b-form-checkbox>
            <b-form-checkbox
              v-if="!pageSettings.isVotingList"
              id="hide-expired-checkbox"
              v-model="filters.hideExpired"
              name="hide-expired-checkbox"
              inline
            >
              <translate>Hide expired</translate>
            </b-form-checkbox>
          </b-col>

          <b-col lg="3" md="4" sm="4" class="mb-3">
            <b-form-select v-model="filters.sortOrder" :options="sortOptions" />
          </b-col>
        </b-row>

        <v-wait for="load items">
          <template slot="waiting">
            <b-card-group deck>
              <ProjectCardPlaceholder v-for="i in 3" :key="i" class="card-dynamic mb-4" />
            </b-card-group>
          </template>
          <b-row v-if="items.length > 0" class="row-cols-1 row-cols-md-2 row-cols-lg-3">
            <b-col v-for="item in items" :key="item.slug" class="mb-4 project-explore-card">
              <slot name="card" :item="item" :pageSettings="pageSettings">
                <ProjectCard img-top :project="item" :pageSettings="pageSettings" class="card-dynamic h-100" />
              </slot>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12" class="h3 my-4 d-flex justify-content-center">
              <b-alert id="explore-project-alert" show variant="light">
                <translate>No projects found</translate>
              </b-alert>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col cols="12" class="d-flex justify-content-center">
              <b-button
                v-if="itemCount > currentPage * pageSize"
                :disabled="loading"
                class="d-flex align-items-center my-4"
                variant="base-ci"
                size="lg"
                style="transition: height 0.3s linear"
                @click="currentPage += 1"
              >
                <b-spinner v-if="loading" />
                <i v-else class="far fa-sync-alt" />
                <translate class="ml-2">Load more</translate>
              </b-button>
            </b-col>
          </b-row>
        </v-wait>
      </b-container>
    </div>
  </v-wait>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import ProjectCard from '@/components/explore/ProjectCard.vue'
import ProjectCardPlaceholder from '@/components/explore/ProjectCardPlaceholder.vue'
import GoogleMapLoader from '@/components/GoogleMapLoader.vue'
import MapPlaceholder from '@/components/MapPlaceholder.vue'
import { ExploreModule } from '@/store/modules/explore'
import { ISelectOption, TGenericObject } from '@/types/base'
import { IPageSettings } from '@/types/cms'
import { IExploreOrganizationMinimal } from '@/types/organizations'
import {
  IExploreProjectMinimal,
  ILocation,
  IOrganizationExploreFilter,
  IProjectCategory,
  IProjectExploreFilters,
} from '@/types/projects'

@Component({
  components: {
    GoogleMapLoader,
    ProjectCard,
    ProjectCardPlaceholder,
    MapPlaceholder,
  },
  name: 'exploration',
})
export default class Exploration extends Vue {
  @Prop() baseExploreURL: string
  @Prop() categoriesURL: string
  @Prop() locationsURL: string
  @Prop() filters: IProjectExploreFilters | IOrganizationExploreFilter
  @Prop({ default: false }) isOrganizationExplore: boolean

  loading = false
  currentPage = 1
  itemCount = 1
  categories: IProjectCategory[] = []
  items: (IExploreProjectMinimal | IExploreOrganizationMinimal)[] = []
  locations: ILocation[] = []

  // filters: IProjectExploreFilters = {
  //   searchString: '',
  //   hideFullyFunded: true,
  //   hideExpired: true,
  //   categories: [],
  //   sortOrder: null,
  //   cmsFilter: null
  // }

  pageSettings: IPageSettings = null
  exploreHTML: string = null
  mapBoundsInitial = true
  googleMapsAccepted = false

  mapBounds = {
    lat: {
      min: null,
      max: null,
    },
    lng: {
      min: null,
      max: null,
    },
  }

  @Watch('filters.sortOrder')
  onSortOrderChange(): void {
    if (this.$wait.is(['load data'])) {
      return
    }

    this.currentPage = 1
    this.fetchItems(false)
    this.mapBoundsInitial = true
  }

  @Watch('filters.searchString')
  @Watch('filters.cmsFilter')
  @Watch('filters.categories')
  @Watch('filters.hideExpired')
  @Watch('filters.hideFullyFunded')
  onFilterChangeForAll(): void {
    if (this.$wait.is(['load data'])) {
      return
    }

    this.currentPage = 1
    this.fetchItems(false)
    this.fetchLocations()
    this.mapBoundsInitial = true
  }

  @Watch('mapBounds', { deep: true })
  mapBoundsChange(): void {
    if (this.mapBoundsInitial) {
      this.mapBoundsInitial = false
      return
    }
    this.currentPage = 1
    this.fetchItems()
  }

  @Watch('currentPage')
  onPageChange(): void {
    this.fetchItems(true, true)
  }

  onBoundsChanged(bounds: google.maps.LatLngBounds | undefined): void {
    if (!bounds) {
      return
    }

    const northEast = bounds.getNorthEast()
    const southWest = bounds.getSouthWest()
    this.mapBounds.lat.min = southWest.lat()
    this.mapBounds.lat.max = northEast.lat() < 0 ? 180 : northEast.lat()
    this.mapBounds.lng.min = southWest.lng()
    this.mapBounds.lng.max = northEast.lng() < 0 ? 180 : northEast.lng()
  }

  get minMaxBounds(): google.maps.LatLngBoundsLiteral | undefined {
    if (this.locations.length > 0) {
      return {
        north: Math.max(...this.locations.map((location) => location.latitude)),
        east: Math.max(...this.locations.map((location) => location.longitude)),
        south: Math.min(...this.locations.map((location) => location.latitude)),
        west: Math.min(...this.locations.map((location) => location.longitude)),
      }
    }
  }

  get exploreProjectsUrl(): string {
    return this.exploreUrl(this.baseExploreURL, true)
  }

  get exploreProjectsUrlNoBounds(): string {
    return this.exploreUrl(this.baseExploreURL, false)
  }

  get exploreLocationsUrl(): string {
    return this.exploreUrl(this.locationsURL, false, false)
  }

  get sortOptions(): ISelectOption[] {
    const options = [
      { value: null, text: this.$gettext('Sort') },
      { value: 'title', text: this.$gettext('A-Z') },
      { value: '-title', text: this.$gettext('Z-A') },
      { value: '-created_at', text: this.$gettext('Newest') },
      { value: 'created_at', text: this.$gettext('Oldest') },
    ]
    if (this.pageSettings.isVotingList && this.pageSettings.displayCurrentVotingRank) {
      options.push(
        { value: 'voting_rank', text: this.$gettext('Votes ascending') },
        { value: '-voting_rank', text: this.$gettext('Votes descending') }
      )
    } else if (!this.pageSettings.isVotingList) {
      options.push(
        { value: 'balance', text: this.$gettext('Balance ascending') },
        { value: '-balance', text: this.$gettext('Balance descending') }
      )
    }
    options.push({ value: 'random', text: this.$gettext('Random') })
    return options
  }

  get pageSize(): number {
    return this.pageSettings?.projectPageSize || 9
  }

  exploreUrl(rootUrl: string, includeBounds = true, paginated = true): string {
    const filterParameters: TGenericObject = {}

    if (this.filters.searchString) {
      filterParameters.search = this.filters.searchString
    }
    if ('hideExpired' in this.filters) {
      if (this.filters.hideExpired) {
        filterParameters.hide_expired = this.filters.hideExpired
      }
      if (this.filters.hideFullyFunded) {
        filterParameters.hide_fully_funded = this.filters.hideFullyFunded
      }
    }
    if (this.filters.categories.length > 0) {
      filterParameters.categories = this.filters.categories.join(',')
    }
    if (this.filters.sortOrder) {
      filterParameters.ordering = this.filters.sortOrder
    }
    if (this.$route.query?.preview_token && this.$route.query?.slug) {
      filterParameters.cms_filter = this.$route.query?.slug
    } else if (this.pageSettings.slug) {
      filterParameters.cms_filter = this.pageSettings.slug
    }

    if (includeBounds) {
      if (this.mapBounds.lat.min) {
        filterParameters.latitude_min = this.mapBounds.lat.min
      }
      if (this.mapBounds.lat.max) {
        filterParameters.latitude_max = this.mapBounds.lat.max
      }
      if (this.mapBounds.lng.min) {
        filterParameters.longitude_min = this.mapBounds.lng.min
      }
      if (this.mapBounds.lng.max) {
        filterParameters.longitude_max = this.mapBounds.lng.max
      }
    }

    const filterKeys = Object.keys(filterParameters)
    const urlFilterParameters =
      filterKeys.length > 0 ? `&${filterKeys.map((key) => `${key}=${filterParameters[key]}`).join('&')}` : ''
    return paginated
      ? `${rootUrl}?page_size=${this.pageSize}&page=${this.currentPage}${urlFilterParameters}`
      : `${rootUrl}?${urlFilterParameters}`
  }

  toggleCategory(category: IProjectCategory): void {
    if (this.filters.categories.includes(category.slug)) {
      for (const [index, item] of this.filters.categories.entries()) {
        if (item === category.slug) {
          this.filters.categories.splice(index, 1)
        }
      }
    } else {
      this.filters.categories.push(category.slug)
    }
  }

  toggleOffAllCategories(): void {
    this.filters.categories = []
  }

  async fetchCategories(): Promise<void> {
    axios.get(this.categoriesURL).then((response) => {
      this.categories = response.data
    })
  }

  async fetchLocations(): Promise<void> {
    if (!this.pageSettings.showMap) {
      return
    }
    await axios.get(this.exploreLocationsUrl).then((response) => {
      this.locations = response.data
    })
  }

  async fetchItems(useBounds = true, append = false): Promise<void> {
    if (this.items.length > 0) {
      this.loading = true
    } else {
      this.$wait.start('load items')
    }

    await axios.get(useBounds ? this.exploreProjectsUrl : this.exploreProjectsUrlNoBounds).then((response) => {
      this.itemCount = response.data.count
      this.items = append ? [...this.items, ...response.data.results] : response.data.results
    })

    this.loading = false
    this.$wait.end('load items')
  }

  async loadData(): Promise<[void, void, void]> {
    const p1 = this.fetchItems()
    const p2 = this.fetchLocations()
    const p3 = this.fetchCategories()
    return Promise.all([p1, p2, p3])
  }

  async created(): Promise<void> {
    this.$wait.start('load data')

    const pageSlug = this.$route.params?.slug || 'home'
    ExploreModule.fetchPageSettings({
      slug: pageSlug,
      previewToken: this.$route.query?.preview_token,
    }).then(() => {
      this.pageSettings = ExploreModule.pageSettingsMap[pageSlug]

      this.filters.sortOrder = this.pageSettings.defaultSortOrder
      if ('hideExpired' in this.filters) {
        this.filters.hideExpired = this.pageSettings.hideExpiredDefault
        this.filters.hideFullyFunded = this.pageSettings.hideFullyFundedDefault
      }

      this.loadData().then(() => {
        this.$wait.end('load data')
      })
    })

    if (this.$cookies.get('cookie_rule_detail')?.includes('google-maps')) {
      this.googleMapsAccepted = true
    }

    if (document.querySelector('#explore-html')) {
      this.exploreHTML = document.querySelector('#explore-html').innerHTML
    }
  }
}
</script>
