<template>
  <b-form @submit="submitForm">
    <div class="row">
      <div class="col-12">
        <p v-translate>
          Choose a name and describe the template so that users know what the template is being used for.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-7">
        <FormModule :title="$gettext('Describe the template')">
          <b-form-group
            v-if="value.documentType !== undefined"
            :label="$gettext('Document type')"
            label-for="select-document-type"
          >
            <b-form-select
              id="select-document-type"
              v-model="value.documentType"
              :options="documentTypeOptions"
              size="sm"
              class="mt-3 already-beautiful"
            />
          </b-form-group>
          <!-- <b-alert :show="selectedDocumentTypeAlreadyExists" variant="warning">
            <p v-translate>A document of this type already exists. Only one of it can exist at a time.</p>
            <router-link :to="{ name: 'update-document', params: { documentSlug: selectedDocumentTypeSlug } }">
              <translate>Click to edit the existing document.</translate>
            </router-link>
          </b-alert> -->
          <b-form-group id="input-group-document-title" :label="$gettext('Title') + '*'" label-for="document-title">
            <b-form-input id="document-title" v-model="value.title" :placeholder="$gettext('Title')" required />
          </b-form-group>
          <b-form-group
            id="input-group-document-title"
            :label="$gettext('Description')"
            label-for="document-description"
          >
            <b-form-textarea
              id="document-description"
              v-model="value.description"
              :placeholder="$gettext('Description')"
            />
          </b-form-group>
        </FormModule>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-button
          v-translate
          type="button"
          variant="default"
          style="margin-right: 1rem"
          :to="{ name: 'list-documents' }"
          >Cancel</b-button
        >
        <b-button type="submit" variant="base-ci">
          <b-spinner v-if="saving" variant="black" label="Spinning" small class="mr-2" />
          <translate>Save and next</translate>
        </b-button>
      </div>
    </div>
  </b-form>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import FormModule from '@/components/FormModule.vue'
import { IDocumentBaseForm } from '@/types/documents'

@Component({
  name: 'base-information-step',
  components: {
    FormModule,
  },
})
export default class BaseInformationStep extends Vue {
  @Prop() value!: IDocumentBaseForm
  @Prop({ default: false }) saving!: boolean

  documentTypeOptions: { text: string; value: string }[] = [
    { text: this.$gettext('Custom document'), value: '32767' },
    { text: this.$gettext('Custom donation receipt'), value: '290' },
    { text: this.$gettext('Custom gewinnspar receipt'), value: '390' },
  ]

  submitForm(event: Event): void {
    event.preventDefault()
    this.$emit('submit', event)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
