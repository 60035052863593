<!--<EmailInput v-model="email" :email="email" :message.sync="errors.email" />-->
<template>
  <b-form-group>
    <b-input-group>
      <template #prepend>
        <b-input-group-text v-if="showPrepend">
          <span class="fal fa-envelope" />
        </b-input-group-text>
      </template>
      <b-form-input
        id="email"
        v-model="valueModel"
        type="email"
        :placeholder="$gettext('email address') + ' *'"
        :state="state"
        :debounce="200"
        aria-describedby="email-feedback"
      />
      <b-form-invalid-feedback :state="state">
        {{ errorMessage }}
      </b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'email-input',
})
export default class EmailInput extends Vue {
  @Prop() value!: string
  @Prop({ default: true }) showPrepend: boolean
  @Prop({ default: null }) message!: string

  @Watch('email')
  onEmailChange(): void {
    this.$emit('update:message', null)
  }

  get valueModel(): string {
    return this.value
  }

  set valueModel(value: string) {
    this.$emit('input', value)
  }

  get errorMessage(): string | null {
    if (this.value.length === 0 && !this.message) {
      return null
    }
    if (this.message) {
      return this.message
    }
    if (this.value.length > 6 && !this.validateEmail(this.value)) {
      return this.$gettext('Please enter a valid email address')
    }
    return null
  }

  get state(): boolean | null {
    if (this.errorMessage) {
      return false
    }
    if (this.validateEmail(this.value)) {
      return true
    }
    return null
  }

  validateEmail(value: string): boolean {
    const isValid =
      /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|.(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/.test(
        value
      )
    if (isValid) {
      this.$emit('valid')
    } else {
      this.$emit('invalid')
    }
    return isValid
  }
}
</script>
