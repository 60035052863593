<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProject } from '@/types/projects'

@Component({
  name: 'project-content-review-status',
})
export default class ProjectContentReviewStatus extends Vue {
  @Prop() project!: IProject

  get label(): string {
    let labelHtml = '-'
    const status = this.project.content_review_status
    if (status) {
      if (status.choice === 2) {
        labelHtml = `<span class="badge badge-success">${status.display}</span>`
      } else if (status.choice === 3) {
        labelHtml = `<span class="badge badge-danger">${status.display}</span>`
      }
    }
    return labelHtml
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
