<template>
  <div>
    {{ title }}
    <i :id="id" class="fas fa-question-circle labeled-tooltip" />
    <b-tooltip :target="id" placement="left">
      {{ description }}
    </b-tooltip>
  </div>
</template>
<script lang="ts">
import { v4 as uuid4 } from 'uuid'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'labeled-tooltip',
})
export default class LabeledTooltip extends Vue {
  @Prop({ default: () => uuid4() }) id!: string
  @Prop({ default: '' }) title!: string
  @Prop({ default: '' }) description!: string
}
</script>

<style scoped lang="scss">
.labeled-tooltip {
  margin-left: 10px;
  cursor: pointer;
}
</style>
