<template>
  <b-card v-if="!project.expired && !project.fully_funded" class="shadow-sm rounded mb-3" no-body :class="customClass">
    <b-overlay id="overlay-background" :show="requestingPayment" blur="0.3rem" spinner-variant="base-ci">
      <b-card-body>
        <b-card-title v-if="!projectDetailSettings.enableFoerderApp">
          <translate>Support project</translate>
        </b-card-title>

        <b-button
          v-if="!foerderAppshowForm && projectDetailSettings.enableFoerderApp"
          block
          size="lg"
          variant="base-ci"
          class="btn hds-donate-btn"
          @click="foerderAppshowForm = true"
        >
          <translate>Donate for need</translate>
        </b-button>

        <div id="private-donation-form">
          <b-form-group>
            <CurrencyInput
              v-show="showDonationForm || !projectDetailSettings.enableFoerderApp"
              v-model="privateDonationForm.amount"
              currency="EUR"
              :limits="project.donation_limits"
              :showPrepend="!projectDetailSettings.enableFoerderApp"
              :stepSize="1 / projectDetailSettings.currencyDisplay.factorOfCurrencyToCoin"
              @valid="amountValid = true"
              @invalid="handleAmountInvalid"
            />
            <b-form-invalid-feedback :state="errorStateAmount">
              {{ formErrors.amount }}
            </b-form-invalid-feedback>
            <b-alert :show="!!coFundingDonationIncreaseInCents" variant="success" class="mt-2">
              {{ donationIncreasedByCoFundingMessage }}
            </b-alert>
            <b-form-checkbox
              v-if="projectDetailSettings.showTakeFeeCheckbox"
              id="take-fees"
              v-model="privateDonationForm.user_takes_fee"
              class="mt-3"
              name="takes_fee"
            >
              <translate>I would like to cover fees of the payment service provider</translate>
            </b-form-checkbox>
          </b-form-group>
          <PrivateDonationForm
            v-show="showDonationForm"
            :form="privateDonationForm"
            :formErrors="formErrors"
            :projectDetailSettings="projectDetailSettings"
          />
          <v-wait for="load payment providers" transition="fade" mode="out-in">
            <template v-if="!projectDetailSettings.enableFoerderApp" slot="waiting">
              <div class="row">
                <div class="col-sm-12 d-flex justify-content-center my-4">
                  <b-spinner variant="primary" label="Spinning" />
                </div>
              </div>
            </template>
            <div class="mt-4">
              <h4 v-if="projectDetailSettings.enableFoerderApp && showDonationForm" class="mt-2 mt-lg-3 mb-2 mb-lg-3">
                * <translate>Donate with</translate>:
              </h4>
              <b-button
                v-for="paymentProvider in paymentProviders"
                v-show="showDonationForm || !projectDetailSettings.enableFoerderApp"
                :key="paymentProvider.title"
                block
                variant="payment-provider"
                @click="donate(paymentProvider)"
              >
                <span v-if="!projectDetailSettings.enableFoerderApp && !paymentProvider.display_name">
                  <translate key="donation-button-text">Donate with</translate>
                </span>
                <span v-if="paymentProvider.display_name">
                  {{ paymentProvider.display_name }}
                </span>
                <b-img class="payment-provider-logo ml-2" :src="paymentProvider.logo" fluid />
              </b-button>

              <b-button
                v-if="'funding_scope' in project"
                v-show="showDonationForm && projectDetailSettings.enableManualBankTransfer"
                block
                variant="payment-provider"
                @click="donateWithManualBankTransfer"
              >
                <translate>Bank transfer</translate>
                <b-img
                  class="payment-provider-logo ml-2"
                  src="/static/img/payments/logo_manual_bank_transfer.png"
                  fluid
                />
              </b-button>

              <b-modal
                v-if="'funding_scope' in project"
                v-model="showManualBankTransferModal"
                size="lg"
                hide-footer
                :title="$gettext('Thank you for your support')"
              >
                <div class="d-block text-left">
                  <p>
                    <translate
                      >Please carry out your classic bank transfer in the next step. Here are the account
                      details</translate
                    >:
                  </p>
                  <p><strong>Name</strong> {{ project.organization?.title || '-' }}</p>
                  <p><strong>IBAN</strong> {{ project.funding_scope?.iban || '-' }}</p>
                  <p><strong>BIC</strong> {{ project.funding_scope?.bic || '-' }}</p>
                  <p>
                    <span v-translate="{ purposeOfTransfer: project.custom_id }"
                      ><strong>Purpose of transfer</strong>%{ purposeOfTransfer }</span
                    >
                  </p>
                  <p>
                    <strong><translate>Sum</translate></strong> {{ privateDonationForm.amount }}
                    {{ getCurrencySymbol() }}
                  </p>
                  <p>
                    <translate
                      ><strong>Important</strong> Please be sure to state the code in the reason for payment. This
                      ensures the allocation to:</translate
                    >
                  </p>
                  <p>
                    <strong><translate>Organization</translate></strong> {{ project.organization?.title || '-' }}
                  </p>
                  <p>
                    <strong><translate>Funding scope</translate></strong> {{ project.funding_scope?.name || '-' }}
                  </p>
                  <p>
                    <strong><translate>Current demand</translate></strong> {{ project.title }}
                  </p>
                  <p>
                    <span v-translate="{ organizationTitle: project.organization.title }"
                      >You will receive the donation receipt from %{ organizationTitle }</span
                    >
                  </p>
                  <p>
                    <translate
                      >We have already sent you an email with all the information for your bank transfer.</translate
                    >
                    <span
                      ><translate>If the email has not arrived, please also check your spam folder.</translate></span
                    >
                  </p>
                </div>
                <b-button
                  class="mt-3 float-right"
                  variant="outline-success"
                  @click="showManualBankTransferModal = false"
                  ><translate>Close</translate></b-button
                >
              </b-modal>
            </div>
          </v-wait>
        </div>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script lang="ts">
import axios from 'axios'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import CurrencyInput from '@/components/explore/CurrencyInput.vue'
import LocaleMixin from '@/mixins/LocaleMixin'
import ToastMixin from '@/mixins/ToastMixin'
import { IProjectDetailSettings } from '@/types/cms'
import { ICoFunding, IPaymentProvider, IPrivatePaymentRequestData } from '@/types/finances'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail, IPrivateDonationForm, IPrivateDonationFormErrors } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

import EmailInput from './EmailInput.vue'
import PrivateDonationForm from './PrivateDonationForm.vue'

@Component({
  name: 'private-donation-area',
  components: {
    PrivateDonationForm,
    CurrencyInput,
  },
})
export default class PrivateDonationArea extends Mixins(ToastMixin, LocaleMixin) {
  @Prop() project: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop() projectDetailSettings!: IProjectDetailSettings
  @Prop({ default: () => [] }) coFundings!: ICoFunding[]
  @Prop({ default: false }) isOrganization!: boolean

  showManualBankTransferModal = false
  amountValid: boolean = null
  requestingPayment = false
  foerderAppshowForm = false
  formErrors: IPrivateDonationFormErrors = {
    amount: '',
    first_name: '',
    last_name: '',
    email: '',
    street: '',
    city: '',
    country: '',
    postal_code: '',
    additional_information: '',
    terms_accepted: '',
    privacy_accepted: '',
  }

  privateDonationForm: IPrivateDonationForm = {
    amount: null,
    first_name: '',
    last_name: '',
    email: '',
    street: '',
    city: '',
    country: 'DE',
    postal_code: '',
    additional_information: '',
    terms_accepted: false,
    privacy_accepted: false,
    needs_receipt: false,
    user_takes_fee: false,
  }

  requiredFields = ['first_name', 'last_name', 'street', 'city', 'postal_code', 'country']
  paymentProviders: IPaymentProvider[] = []

  get customClass() {
    return this.projectDetailSettings.enableFoerderApp ? 'border-less-card' : ''
  }

  get showDonationForm(): boolean {
    if (this.projectDetailSettings.enableFoerderApp) {
      return this.foerderAppshowForm
    }
    return !!this.privateDonationForm.amount
  }

  get amountInCents(): number {
    if (!this.privateDonationForm.amount) {
      return 0
    }
    return Math.round(100 * this.privateDonationForm.amount)
  }

  get coFundingDonationIncreaseInCents(): number {
    if (this.coFundings.length === 0 || !this.amountInCents) {
      return 0
    }
    return this.coFundings
      .map((coFunding) => {
        if (!coFunding.budget_left.in_cents) {
          return 0
        }
        if (coFunding.donation_min.in_cents > this.amountInCents) {
          return 0
        }
        if (coFunding.funding_value.in_cents) {
          if (this.amountInCents >= coFunding.donation_min.in_cents) {
            return Math.min(coFunding.funding_value.in_cents, coFunding.budget_left.in_cents)
          }
        } else if (coFunding.funding_percentage) {
          return Math.round(
            Math.min((this.amountInCents * coFunding.funding_percentage) / 100, coFunding.donation_max.in_cents)
          )
        }
        return 0
      })
      .reduce((x, y) => x + y, 0)
  }

  get donationIncreasedByCoFundingMessage(): string {
    if (this.coFundings.length > 0) {
      return this.$gettextInterpolate(this.$gettext('Your donation will be increased by %{ increasedAmount }'), {
        increasedAmount: this.centsToCurrency(
          Math.min(
            this.coFundingDonationIncreaseInCents,
            this.project.donation_limits.max_in_cents - this.amountInCents
          )
        ),
      })
    }
    return ''
  }

  get formHasErrors(): boolean {
    for (const key of Object.keys(this.formErrors)) {
      if (this.formErrors[key]) {
        return true
      }
    }
  }

  @Watch('privateDonationForm', { deep: true })
  onMyFormChanged(): void {
    if (this.formHasErrors) {
      this.validateInput()
    }
  }

  handleAmountInvalid() {
    this.amountValid = false
    this.formErrors.amount = ''
  }

  validateInput() {
    this.clearErrors()
    let validated = true
    const emailInput = new EmailInput()
    const validatedEmail = emailInput.validateEmail(this.privateDonationForm.email)
    if (!this.amountValid) {
      validated = false
      this.formErrors.amount = this.$gettext('Please enter a donation amount.')
    }
    if (!validatedEmail) {
      this.formErrors.email = this.$gettext('Please enter a valid email address')
      validated = false
    }
    for (const fieldName of this.requiredFields) {
      const value = this.privateDonationForm[fieldName]
      if (!value) {
        this.formErrors[fieldName] = this.$gettext('This field is required')
        validated = false
      }
    }
    return validated
  }

  validateTermsAndPrivacy(): boolean {
    let isValid = true
    if (this.projectDetailSettings.showTermsOfUseCheckbox && !this.privateDonationForm.terms_accepted) {
      this.formErrors.terms_accepted = this.$gettext('Please accept the terms of use')
      isValid = false
    }
    if (this.projectDetailSettings.showPrivacyAgreementCheckbox && !this.privateDonationForm.privacy_accepted) {
      this.formErrors.privacy_accepted = this.$gettext('Please accept the privacy policy')
      isValid = false
    }
    return isValid
  }

  donate(paymentProvider: IPaymentProvider): void {
    if (!this.validateInput() || !this.validateTermsAndPrivacy()) {
      return
    }
    const extraData = localStorage.getItem('donation_extra_data') || this.$route.query.source?.toString() || ''
    this.requestingPayment = true
    const valueWithFees = this.getNetValueWithFeesInCents(this.amountInCents, paymentProvider)
    const data: IPrivatePaymentRequestData = {
      net_value_in_cents: this.amountInCents,
      amount_in_cents: valueWithFees,
      first_name: this.privateDonationForm.first_name,
      last_name: this.privateDonationForm.last_name,
      email: this.privateDonationForm.email,
      street: this.privateDonationForm.street,
      city: this.privateDonationForm.city,
      country: this.privateDonationForm.country,
      postal_code: this.privateDonationForm.postal_code,
      additional_information: this.privateDonationForm.additional_information,
      terms_of_use_accepted: this.privateDonationForm.terms_accepted,
      privacy_policy_accepted: this.privateDonationForm.privacy_accepted,
      needs_receipt: this.privateDonationForm.needs_receipt,
      quantity: 1,
      currency_code: 'EUR',
      provider: paymentProvider.slug,
      user_takes_fee: this.privateDonationForm.user_takes_fee,
      extra_data: extraData,
    }
    if (this.isOrganization) {
      data.promoter = this.project.slug
    } else {
      data.project = this.project.slug
    }

    axios
      .post(API_URLS.PRIVATE_PAYMENT_REQUESTS.DONATE, data)
      .then((response) => {
        this.requestingPayment = false
        window.location = response.data.payment_url
      })
      .catch((error) => {
        this.requestingPayment = false
        if (error.response && error.response.data) {
          if (!Array.isArray(error.response.data.non_field_errors)) {
            this.makeToast('danger', this.$gettext('Whoops!'), this.$gettext('an error occurred'))
            return
          }
          for (const entry of Object.entries(error.response.data)) {
            const [key, value] = entry
            const errorMsg = Array.isArray(value) ? value[0] : value
            if (key in this.formErrors) {
              this.formErrors[key] = errorMsg
            } else {
              this.makeToast('danger', 'Error', errorMsg)
            }
          }
        }
      })
  }

  donateWithManualBankTransfer() {
    if (!this.validateInput() || !this.validateTermsAndPrivacy()) {
      return
    }
    const data = {
      project: this.project.slug,
      amount_in_cents: this.amountInCents,
      first_name: this.privateDonationForm.first_name,
      last_name: this.privateDonationForm.last_name,
      email: this.privateDonationForm.email,
      street: this.privateDonationForm.street,
      city: this.privateDonationForm.city,
      country: this.privateDonationForm.country,
      postal_code: this.privateDonationForm.postal_code,
      additional_information: this.privateDonationForm.additional_information,
      terms_of_use_accepted: this.privateDonationForm.terms_accepted,
      privacy_policy_accepted: this.privateDonationForm.privacy_accepted,
      needs_receipt: this.privateDonationForm.needs_receipt,
      quantity: 1,
      currency_code: 'EUR',
    }
    axios
      .post(API_URLS.MANUAL_BANK_TRANSFER.DONATE, data)
      .then(() => {
        this.requestingPayment = false
        this.foerderAppshowForm = false
        this.showManualBankTransferModal = true
      })
      .catch((error) => {
        this.requestingPayment = false
        if (error.response && error.response.data) {
          if (!Array.isArray(error.response.data)) {
            this.makeToast('danger', this.$gettext('Whoops!'), this.$gettext('an error occurred'))
            return
          }
          for (const entry of Object.entries(error.response.data)) {
            const [key, value] = entry
            const errorMsg = Array.isArray(value) ? value[0] : value
            if (key in this.formErrors) {
              this.formErrors[key] = errorMsg
            } else {
              this.makeToast('danger', key, errorMsg)
            }
          }
        }
      })
  }

  get errorStateAmount(): boolean {
    return this.amountValid ? true : !this.formErrors.amount
  }

  getNetValueWithFeesInCents(value: number, paymentProvider: IPaymentProvider): number {
    return this.privateDonationForm.user_takes_fee
      ? Math.round(
          (value + Number.parseFloat(paymentProvider.fee_base_in_cents)) /
            (1 - Number.parseFloat(paymentProvider.fee_factor))
        )
      : value
  }

  clearErrors() {
    for (const key in this.formErrors) {
      this.formErrors[key] = ''
    }
  }

  mounted() {
    this.$wait.start('load payment providers')
    axios.get(API_URLS.EXPLORE.PAYMENT_PROVIDERS).then((response) => {
      this.paymentProviders = response.data.results
      this.$wait.end('load payment providers')
    })
    this.privateDonationForm.user_takes_fee =
      this.projectDetailSettings.forceTakeFee || this.projectDetailSettings.takeFeeCheckboxDefaultValue
  }
}
</script>
