import { translate } from 'vue-gettext'
const { gettext: $gettext } = translate

export const statutoryPurposeOptions = (lang: string) => {
  return {
    p52_1: $gettext('Wissenschaft und Forschung (§52 Abs.2 Satz 1 Nr.1 AO)', lang),
    p52_2: $gettext('Religion (§52 Abs.2 Satz 1 Nr.2 AO)', lang),
    p52_3: $gettext('Öffentl. Gesundheitswesen (§52 Abs.2 Satz 1 Nr.3 AO)', lang),
    p52_4: $gettext('Jugend- und Altenhilfe (§52 Abs.2 Satz 1 Nr.4 AO)', lang),
    p52_5: $gettext('Kunst und Kultur (§52 Abs.2 Satz 1 Nr.5 AO)', lang),
    p52_6: $gettext('Denkmalschutz (§52 Abs.2 Satz 1 Nr.6 AO)', lang),
    p52_7: $gettext('Erziehung, Volks- und Berufsbildung (§52 Abs.2 Satz 1 Nr.7 AO)', lang),
    p52_8: $gettext('Naturschutz, Landschaftspflege, etc.(§ 52 Nr. 8 AO )', lang),
    p52_9: $gettext('Wohlfahrtswesen (§52 Abs.2 Satz 1 Nr.9 AO)', lang),
    p52_10: $gettext('Zivilgeschädigte und Behinderte (§52 Abs.2 Satz 1 Nr.10 AO)', lang),
    p52_11: $gettext('Lebensrettung (§52 Abs.2 Satz 1 Nr.11 AO)', lang),
    p52_12: $gettext('Katastrophen- und Zivilschutz (§52 Abs.2 Satz 1 Nr.12 AO)', lang),
    p52_13: $gettext('Völkerverständigung (§52 Abs.2 Satz 1 Nr.13 AO)', lang),
    p52_14: $gettext('Tierschutz (§52 Abs.2 Satz 1 Nr.14 AO)', lang),
    p52_15: $gettext('Entwicklungszusammenarbeit (§52 Abs.2 Satz 1 Nr.15 AO)', lang),
    p52_16: $gettext('Verbraucherschutz (§52 Abs.2 Satz 1 Nr.16 AO)', lang),
    p52_17: $gettext('Fürsorge für Strafgefangene (§52 Abs.2 Satz 1 Nr.17 AO)', lang),
    p52_18: $gettext('Gleichberechtigung (§52 Abs.2 Satz 1 Nr.18 AO)', lang),
    p52_19: $gettext('Schutz von Ehe und Familie (§52 Abs.2 Satz 1 Nr.19 AO)', lang),
    p52_20: $gettext('Kriminalprävention (§52 Abs.2 Satz 1 Nr.20 AO)', lang),
    p52_21: $gettext('Sport (§52 Abs.2 Satz 1 Nr.21 AO)', lang),
    p52_22: $gettext('Heimatpflege und Heimatkunde (§52 Abs.2 Satz 1 Nr.22 AO)', lang),
    p52_23: $gettext(
      'Tierzucht, Pflanzenzucht, Kleingärtnerei, Brauchtum, Karneval, Soldatenbetreuung, Amateurfunken, Modellflug und Hundesport (§52 Abs.2 Satz 1 Nr.23 AO)',
      lang
    ),
    p52_24: $gettext('Demokratisches Staatswesen (§52 Abs.2 Satz 1 Nr.24 AO)', lang),
    p52_25: $gettext('Bürgerschaftliches Engagement (§52 Abs.2 Satz 1 Nr.25 AO)', lang),
    p52_26: $gettext('Pflege von Friedhöfen für nichtbestattungspflichtige Kinder. (§52 Abs.2 Satz 1 Nr.26 AO)', lang),
    p53: $gettext('Mildtätige Zwecke', lang),
    p54: $gettext('Kirchliche Zwecke', lang),
    p51: $gettext('Allgemeines', lang),
  }
}

export const reviewStatusOptions = (lang: string) => {
  return {
    needs_review: $gettext('Needs review', lang),
    public: $gettext('Public', lang),
    rejected: $gettext('Rejected', lang),
    accepted: $gettext('Accepted', lang),
  }
}

export const organizationTypeOptions = (lang: string) => {
  return {
    association: $gettext('Non-profit Association, gGmbH, gAG, gUG', lang),
    community: $gettext('Church congregation, association of municipalities, city, university, etc.', lang),
    public_foundation: $gettext('Foundation under public law', lang),
    private_foundation: $gettext('Foundation under private law', lang),
    profit: $gettext('For-profit organization (GmbH, AG, UG, Initiative, For-profit Association etc.)', lang),
  }
}

export const contentStatusOptions = (lang: string) => {
  return {
    accepted: $gettext('Content accepted', lang),
    rejected: $gettext('Content rejected', lang),
  }
}

export const progressStatusOptions = (lang: string) => {
  return {
    in_progress: $gettext('In progress', lang),
    complete: $gettext('Complete', lang),
  }
}
