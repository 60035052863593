<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IProject } from '@/types/projects'

@Component({
  name: 'project-status',
})
export default class ProjectStatus extends Vue {
  @Prop() project!: IProject

  get label(): string {
    let labelHtml = ''
    const status = this.project.status
    switch (status.choice) {
      case 1: {
        labelHtml = `<span class="badge badge-warning">${status.display}</span>`
        break
      }
      case 2: {
        labelHtml = `<span class="badge badge-success">${status.display}</span>`
        break
      }
      case 3: {
        {
          labelHtml = `<span class="badge badge-light">${status.display}</span>`
          // No default
        }
        break
      }
    } // archived
    return labelHtml
  }
}
</script>
