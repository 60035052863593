<template>
  <b-card class="shadow-sm rounded mb-3">
    <b-card-title>
      <translate>Support project</translate>
    </b-card-title>

    <b-tabs
      v-if="isActiveVoting && emailVotingEnabled && votingCodesEnabled"
      v-model="activeTab"
      fill
      content-class="mt-2"
      @input="tabChanged"
    >
      <b-tab key="code-tab" :title="$gettext('Voting code')">
        <CodeInput
          v-if="!voteCount"
          v-model="code"
          :blockLength="codeFormat?.blockLength || 4"
          :errorMessage="errorMessage"
          :isValid="codeInputValid"
          @input="redemptionError = null"
        />
        <p v-else>
          <translate
            key="session-codes-1"
            :translate-n="voteCount"
            :translate-params="{ voteCount: voteCount }"
            translate-plural="Support this project with %{ voteCount } votes"
          >
            Support this project with %{ voteCount } vote
          </translate>
        </p>
      </b-tab>
      <b-tab key="email-tab" :title="$gettext('Email address')">
        <EmailInput v-model="email" @valid="emailValid = true" @invalid="emailValid = false" />
      </b-tab>
    </b-tabs>
    <div v-else>
      <div v-if="isActiveVoting">
        <CodeInput
          v-if="votingCodesEnabled && !voteCount"
          v-model="code"
          :blockLength="codeFormat?.blockLength || 4"
          :errorMessage="errorMessage"
          :isValid="codeInputValid"
          @input="redemptionError = null"
        />
        <p v-else-if="votingCodesEnabled">
          <translate
            key="session-codes-1"
            :translate-n="voteCount"
            :translate-params="{ voteCount: voteCount }"
            translate-plural="Support this project with %{ voteCount } votes"
          >
            Support this project with %{ voteCount } vote
          </translate>
        </p>
        <EmailInput
          v-else-if="emailVotingEnabled"
          v-model="email"
          @valid="emailValid = true"
          @invalid="emailValid = false"
        />
      </div>
      <SfAlert :show="isFutureVoting">
        <translate key="voting-not-started">The voting has not started yet.</translate>
      </SfAlert>
      <SfAlert :show="isExpiredVoting">
        <translate key="voting-has-ended">The voting has ended.</translate>
      </SfAlert>
    </div>

    <b-alert
      variant="danger"
      class="mt-2 mb-0"
      dismissible
      :show="!!votingErrorMessage"
      @dismissed="votingError = null"
    >
      <span>{{ votingErrorMessage }}</span>
    </b-alert>

    <div v-if="showEmailConsent" class="my-4">
      <h5 v-html="projectDetailSettings.emailConsentCheckboxLabel" />
      <b-form-checkbox v-model="emailConsent">
        <span v-html="projectDetailSettings.emailConsentAdditionalText" />
      </b-form-checkbox>
    </div>

    <b-button
      v-if="isActiveVoting"
      id="vote-btn"
      variant="base-ci"
      block
      :disabled="voteButtonDisabled"
      @click="voteOnProject"
    >
      <b-spinner v-if="saving" small />
      <translate v-else key="redeem-code-voting">Vote now</translate>
    </b-button>
    <!-- Old code is still inserting html with this info on the top of the page, but might be removed in future, in which case this should be good enough -->
    <!-- <b-alert variant="success" class="mt-2 mb-0" :show="!!voteCount">
      <translate>Your currently redeemed voting codes:</translate>
      <ul class="pl-4">
        <li v-for="code in availableCodes" :key="code.code_string">
          {{ code.pretty_code_string }}
        </li>
      </ul>
    </b-alert> -->
    <b-alert variant="success" class="my-2" dismissible :show="!!redemptionSuccessMessage">
      <span>{{ redemptionSuccessMessage }}</span>
    </b-alert>
    <b-alert
      variant="danger"
      class="my-2"
      dismissible
      :show="!!redemptionErrorMessage"
      @dismissed="redemptionError = null"
    >
      <span>{{ redemptionErrorMessage }}</span>
    </b-alert>
    <VerifyVoteModal :visible="showVerifyVoteModal" @hidden="showVerifyVoteModal = false" />
  </b-card>
</template>

<script lang="ts">
import axios from 'axios'
import { load as loadReCaptcha, ReCaptchaInstance } from 'recaptcha-v3'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import VerifyVoteModal from '@/components/modals/VerifyVoteModal.vue'
import SfAlert from '@/components/SfAlert.vue'
import CodeInputAreaMixin from '@/mixins/CodeInputAreaMixin'
import ToastMixin from '@/mixins/ToastMixin'
import { TGenericObject } from '@/types/base'
import { IExploreOrganizationDetail } from '@/types/organizations'
import { IExploreProjectDetail, IFPData, IFPDocument } from '@/types/projects'
import { ISessionVotingCode, IVoteData } from '@/types/votings'
import { API_URLS } from '@/utils/helpers'

import CodeInput from './CodeInput.vue'
import EmailInput from './EmailInput.vue'

@Component({
  name: 'voting-area',
  components: {
    CodeInput,
    EmailInput,
    SfAlert,
    VerifyVoteModal,
  },
})
export default class VotingArea extends Mixins(CodeInputAreaMixin, ToastMixin) {
  @Prop() project!: IExploreProjectDetail | IExploreOrganizationDetail
  @Prop({ default: false }) isOrganization!: boolean

  activeTab = 0
  email = ''
  emailConsent = false
  emailValid = false
  votingError: TGenericObject = null
  fpData: IFPData = null
  reCaptchaInstance: ReCaptchaInstance = null
  availableCodes: ISessionVotingCode[] = []
  showVerifyVoteModal = false

  get emailVotingEnabled(): boolean {
    return this.projectDetailSettings?.enableEmailVoting || false
  }

  get votingCodesEnabled(): boolean {
    return this.projectDetailSettings?.enableVotingCodes || false
  }

  get redemptionSuccessMessage(): string {
    if (this.redemption) {
      return this.$gettextInterpolate(this.$gettext('Successfuly voted on %{ projectName }'), {
        projectName: this.project.title,
      })
    }
  }

  get votingErrorMessage(): string {
    if (this.votingError) {
      if (this.votingError?.email) {
        return this.votingError?.email.join(' - ')
      }
      return this.votingError?.non_field_errors.join(' - ')
    }
  }

  get emailInputActive(): boolean {
    if (this.emailVotingEnabled) {
      if (this.votingCodesEnabled) {
        return this.activeTab === 1
      }
      return true
    }
    return false
  }

  get showEmailConsent(): boolean {
    if (this.projectDetailSettings.enableEmailConsent) {
      return this.emailInputActive
    }
    return false
  }

  get voteCount(): number {
    return this.availableCodes.map((code) => code.value).reduce((pv, cv) => pv + cv, 0)
  }

  get voteButtonDisabled(): boolean {
    if (this.emailInputActive) {
      return !this.emailValid || this.saving
    }
    return !(this.codeInputValid || !!this.voteCount) || this.saving
  }

  get isFutureVoting(): boolean {
    return this.$moment(this.project.voting.voting_start) > this.$moment()
  }

  get isExpiredVoting(): boolean {
    return this.$moment(this.project.voting.voting_end) < this.$moment()
  }

  get isActiveVoting(): boolean {
    return !(this.isFutureVoting || this.isExpiredVoting)
  }

  tabChanged() {
    this.votingError = null
  }

  collectFPID(slug: string): void {
    let chars = new Array<number>()
    for (let i = 0; i < this.projectDetailSettings.fpGen.fpInterpreter.length; i += 2) {
      chars.push(Number.parseInt(this.projectDetailSettings.fpGen.fpInterpreter.slice(i, i + 2), 16))
    }

    //TODO check if the slight difference in behaviour would be an issue
    // eslint-disable-next-line unicorn/prefer-code-point
    eval(chars.map((char) => String.fromCharCode(char)).join(''))

    chars = new Array<number>()
    for (let i = 0; i < this.projectDetailSettings.fpGen.fpFinder.length; i += 2) {
      chars.push(Number.parseInt(this.projectDetailSettings.fpGen.fpFinder.slice(i, i + 2), 16))
    }
    // eslint-disable-next-line unicorn/prefer-code-point
    eval(chars.map((char) => String.fromCharCode(char)).join(''))

    const hackyDoc = document as IFPDocument
    this.fpData = hackyDoc.fpt(slug)
    /* eslint-enable */
  }

  async voteOnProject(): Promise<void> {
    // API sucks and accepts both email and code values as 'email',
    // so we fill this based on settings and active tab
    let emailVal = ''
    let isSessionCode = false
    if ( (this.votingCodesEnabled && !this.emailVotingEnabled) || this.activeTab === 0) {
      if (this.voteCount) {
        emailVal = JSON.stringify(this.availableCodes.map((code) => code.code_string))
        isSessionCode = true
      } else {
        emailVal = this.code
      }
    }
    if ( (this.emailVotingEnabled && !this.votingCodesEnabled) || this.activeTab === 1) {
      emailVal = this.email
    }

    const voteData: IVoteData = {
      email: emailVal,
      email_consent: this.emailConsent,
      extra_data: this.$route.fullPath,
      fp_id: this.fpData?.fp || '',
      is_private_browsing_mode: this.fpData?.pm || false,
      'g-recaptcha-response': '',
    }

    this.saving = true
    if (this.projectDetailSettings?.recaptchaKey) {
      await this.reCaptchaInstance
        .execute('vote')
        .then((token) => {
          voteData['g-recaptcha-response'] = token
        })
        .catch(() => {
          this.saving = false
        })
    }
    if (this.isOrganization) {
      voteData.projectpromoter = this.project.slug
    } else {
      voteData.project = this.project.slug
    }

    axios
      .post(API_URLS.V3.VOTE(this.project.voting.slug), voteData)
      .then((response) => {
        this.saving = false

        if (response.data?.verification_needed) {
          this.showVerifyVoteModal = true
        } else {
          this.makeToast(
            'success',
            this.$gettext('Voted!'),
            this.$gettextInterpolate(this.$gettext('Voted for %{ projectName }'), { projectName: this.project.title })
          )
        }

        this.$emit('vote', response.data)

        if (isSessionCode) {
          window.sessionStorage.removeItem('voting_codes')
          this.availableCodes = []
          document.getElementById('votingcodes-message').classList.add('d-none')
        }
        this.code = ''
        this.email = ''
      })
      .catch((error) => {
        this.saving = false
        this.votingError = error.response.data
      })
  }

  created(): void {
    if (window.sessionStorage.voting_codes) {
      this.availableCodes = JSON.parse(window.sessionStorage.voting_codes)
    }

    if (this.projectDetailSettings?.recaptchaKey) {
      // Using reCaptcha v3 keys, and we probably use v2 everywhere
      loadReCaptcha(this.projectDetailSettings.recaptchaKey, {
        renderParameters: {
          container: '#vote-btn',
        },
      }).then((instance) => {
        this.reCaptchaInstance = instance
      })
    }

    if (this.project && this.projectDetailSettings?.fpGen) {
      this.collectFPID(this.project.slug)
    }
  }
}
</script>
