import { Component, Vue } from 'vue-property-decorator'

import { PlatformSettingsModule } from '@/store/modules/platformSettings'

@Component({
  name: 'platform-settings-mixin',
})
export default class PlatformSettingsMixin extends Vue {
  get platformFeatures() {
    return PlatformSettingsModule.features
  }

  get platformProjectSettings() {
    return PlatformSettingsModule.project_settings
  }

  get platformPages() {
    return PlatformSettingsModule.pages
  }

  get globalContent() {
    return PlatformSettingsModule.global_content
  }

  created(): void {
    PlatformSettingsModule.fetchPlatformSettings()
  }
}
