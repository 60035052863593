<template>
  <span class="labels" v-html="label" />
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import { IOrganization, IProject } from '@/types/projects'

@Component({
  name: 'project-voting-review-status',
})
export default class ProjectVotingReviewStatus extends Vue {
  @Prop() project!: IProject | IOrganization

  get label(): string {
    let labelHtml = '-'
    const status = this.project.voting_review_status
    if (status) {
      switch (status.choice) {
        case 2: {
          labelHtml = `<span class="badge badge-success">${status.display}</span>`
          break
        }
        case 3: {
          labelHtml = `<span class="badge badge-danger">${status.display}</span>`
          break
        }
        case 4: {
          {
            labelHtml = `<span class="badge badge-success">${status.display}</span>`
            // No default
          }
          break
        }
      } // accepted
    }
    if (this.project.needs_voting_review) {
      const needsReviewLabel = `<span class="badge badge-warning">${this.$gettext('Needs review')}</span>`
      if (labelHtml.length > 1) {
        labelHtml += needsReviewLabel
      } else {
        labelHtml = needsReviewLabel
      }
    }
    return labelHtml
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
