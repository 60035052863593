import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ToastMixin extends Vue {
  makeToast(variant: string, title: string, body: string) {
    this.$bvToast.toast(body, {
      title,
      toaster: 'b-toaster-top-left old',
      variant,
      solid: true,
      toastClass: 'sf-toast',
    })
  }
}
