import axios from 'axios'
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import { IBalance, ISlugTitle } from '@/types/base'
import { IUserProfile } from '@/types/users'
import { API_URLS } from '@/utils/helpers'

export interface IUserState {
  profile: IUserProfile | null
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  profile: IUserProfile | null = JSON.parse(localStorage.getItem('profile'))

  get hasPermission() {
    return (permissionName: string) => {
      if (this.profile?.is_superuser) {
        return true
      }

      return this.profile?.permissions.includes(permissionName)
    }
  }

  @Mutation
  FETCH_PROFILE(profile: IUserProfile): void {
    // If fetched the object should not be the same as the one currently in store (even if same values)
    const profileChanged = profile !== this.profile
    this.profile = profile

    if (profileChanged) {
      localStorage.setItem('profile', JSON.stringify(this.profile))
      localStorage.setItem('profileTimestamp', Date.now().toString())
    }
  }

  @Action({ commit: 'FETCH_PROFILE' })
  async fetchProfile(force = false) {
    const profileTimestamp = localStorage.getItem('profileTimestamp')
    let refreshProfile = true

    if (!force && profileTimestamp && !this.profile?.is_staff) {
      // Always refresh if user is staff or update is forced, otherwise refresh profile if last local save is older than 5 minutes
      refreshProfile = (Date.now() - Number.parseInt(profileTimestamp)) / (1000 * 60) > 5
    }

    if (refreshProfile) {
      return axios.get(API_URLS.USERS.PROFILE).then((response) => response.data)
    }

    return this.profile
  }

  @Mutation
  FETCH_BALANCE(balance: IBalance): void {
    this.profile.balance = balance
    localStorage.setItem('profile', JSON.stringify(this.profile))
  }

  @Action({ commit: 'FETCH_BALANCE' })
  async fetchBalance(): Promise<void> {
    return axios.get(API_URLS.USERS.BALANCE).then((response) => response.data.balance)
  }

  @Mutation
  FETCH_FAVORITE_PROJECTS(favorite_projects: ISlugTitle[]): void {
    this.profile.favorite_projects = favorite_projects
    localStorage.setItem('profile', JSON.stringify(this.profile))
  }

  @Action({ commit: 'FETCH_FAVORITE_PROJECTS' })
  async fetchFavoriteProjects(): Promise<void> {
    return axios.get(API_URLS.USERS.FAVORITE_PROJECTS).then((response) => response.data)
  }
}

export const UserModule = getModule(User)
