var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-modal',_vm._g({attrs:{"id":"donation-success-modal","size":"lg","centered":""},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("Thank you for your support!")])]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('SocialMediaArea',{attrs:{"id":"donation-modal-social-media","project":_vm.project,"projectDetailSettings":_vm.projectDetailSettings}}),_c('b-card',{staticClass:"shadow-sm rounded mb-3"},[(_vm.project.photos?.length)?_c('b-img',{attrs:{"src":_vm.project.photos[0].src,"fluid":"","alt":_vm.project.title + ' cover image'}}):_vm._e(),_c('hr'),_c('div',[_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
                donationAmount: _vm.formatCurrency(
                  _vm.donationResult?.prettified_amount_in_coins,
                  _vm.donationResult?.prettified_amount_in_currency
                ),
                projectTitle: _vm.project.title,
              }),expression:"{\n                donationAmount: formatCurrency(\n                  donationResult?.prettified_amount_in_coins,\n                  donationResult?.prettified_amount_in_currency\n                ),\n                projectTitle: project.title,\n              }"}]},[_vm._v(" You supported the project "),_c('strong',[_c('span',{staticClass:"project_title"},[_vm._v("%{ projectTitle }")])]),_vm._v(" with "),_c('strong',[_c('span',{staticClass:"coins"},[_vm._v("%{ donationAmount }")])]),_vm._v(". ")]),_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
                missingAmount: _vm.formatCurrency(
                  _vm.donationResult?.project_pretty_coins_to_go,
                  _vm.donationResult?.project_pretty_currency_to_go
                ),
              }),expression:"{\n                missingAmount: formatCurrency(\n                  donationResult?.project_pretty_coins_to_go,\n                  donationResult?.project_pretty_currency_to_go\n                ),\n              }"}]},[_vm._v(" Just "),_c('strong',[_c('span',{staticClass:"missing_amount"},[_vm._v("%{ missingAmount }")])]),_vm._v(" are needed to fund the project completely. ")])])],1)],1),_c('div',{staticClass:"col-md-6"},_vm._l((_vm.donationResult?.log_donation_companies_info),function(company){return _c('b-row',{key:company.comp_slug},[_c('b-card',{staticClass:"shadow-sm rounded mb-3"},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[(company.comp_logo)?_c('b-img',{staticClass:"rounded",attrs:{"src":company.comp_logo,"fluid":"","alt":company.title + ' logo'}}):_vm._e()],1),_c('hr'),_c('div',[_c('p',{directives:[{name:"translate",rawName:"v-translate",value:({
                  donationAmount: _vm.formatCurrency(company.comp_donated_amt_coins, company.comp_donated_amt_currency),
                  companyName: company.comp_name,
                }),expression:"{\n                  donationAmount: formatCurrency(company.comp_donated_amt_coins, company.comp_donated_amt_currency),\n                  companyName: company.comp_name,\n                }"}]},[_vm._v(" You have distributed "),_c('strong',[_c('span',{staticClass:"coins"},[_vm._v("%{ donationAmount }")])]),_vm._v(" from "),_c('strong',[_c('span',{staticClass:"company_name"},[_vm._v("%{ companyName }")])]),_vm._v(". ")])])])],1)}),1)])]},proxy:true},{key:"modal-footer",fn:function({ close }){return [_c('b-button',{staticClass:"mr-1",attrs:{"id":"close-delete-modal-btn","variant":"default"},on:{"click":close}},[_c('translate',[_vm._v("Close")])],1)]}}]),model:{value:(_vm.isOpenModel),callback:function ($$v) {_vm.isOpenModel=$$v},expression:"isOpenModel"}},_vm.$listeners))
}
var staticRenderFns = []

export { render, staticRenderFns }