<template>
  <div>
    <BreadcrumbHeader :breadcrumbs="breadcrumbs" />

    <div class="row">
      <div class="no-float-sm no-float-xs col-12 col-md-3">
        <SfSteps ref="sfSteps" v-model="steps" />
      </div>
      <div class="no-float-sm no-float-xs col-12 col-md-9 step-content">
        <v-wait for="load">
          <template slot="waiting">
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-center">
                <b-spinner variant="primary" label="Spinning" />
              </div>
            </div>
          </template>
          <BaseInformationStep v-if="steps[0].active" v-model="documentForm" :saving="isSaving" @submit="submitForm" />
          <UploadFileStep
            v-if="steps[1].active"
            v-model="newDocumentFile"
            :document="document"
            @file-uploaded="loadDocument"
            @file-deleted="loadDocument"
            @next="sfSteps.nextStep()"
            @back="sfSteps.previousStep()"
          />
          <MapContentStep
            v-if="steps[2].active && document"
            v-model="selectedMappingFields"
            :document="document"
            :saving="isSaving"
            @back="sfSteps.previousStep()"
            @save="saveMapping"
            @finish="finishEdit"
          />
        </v-wait>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import decamelizeKeys from 'decamelize-keys'
import { Component, Mixins, Ref } from 'vue-property-decorator'

import BreadcrumbHeader from '@/components/BreadCrumbHeader.vue'
import BaseInformationStep from '@/components/documents/BaseInformationStep.vue'
import MapContentStep from '@/components/documents/MapContentStep.vue'
import UploadFileStep from '@/components/documents/UploadFileStep.vue'
import SfSteps from '@/components/SfSteps.vue'
import ToastMixin from '@/mixins/ToastMixin'
import { IBreadcrumb, IStep, TGenericObject } from '@/types/base'
import { IDocument, IDocumentBaseForm, IMappingField } from '@/types/documents'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'update-document',
  components: {
    BaseInformationStep,
    BreadcrumbHeader,
    UploadFileStep,
    MapContentStep,
    SfSteps,
  },
})
export default class UpdateDocument extends Mixins(ToastMixin) {
  @Ref() readonly sfSteps!: SfSteps
  newDocumentFile: File = null
  document: IDocument = null
  documentForm: IDocumentBaseForm = {
    title: '',
    description: '',
    documentType: 32_767, // Integer of custom document type
  }

  isSaving = false
  selectedMappingFields: TGenericObject = {}

  steps: IStep[] = [
    { value: 'base-information', text: this.$gettext('Base Information'), active: true, completed: true },
    { value: 'upload-file', text: this.$gettext('Upload File'), active: false, completed: false },
    { value: 'map-content', text: this.$gettext('Map Content'), active: false, completed: false },
  ]

  get breadcrumbs(): IBreadcrumb[] {
    return [
      {
        title: this.$gettext('Administrate Templates'),
        url: { name: 'list-documents' },
        routerUrl: true,
      },
      {
        title: this.$gettext('Edit'),
        url: '#',
      },
    ]
  }

  get hasMappings(): boolean {
    return this.document.fields.length && this.document.fields.some((field) => field.mapping)
  }

  get isEditableDocumentType(): boolean {
    return [290, 390, 32_767].includes(this.document ? this.document.document_type.choice : 0)
  }

  setFormDefault(): void {
    this.documentForm.title = this.document.title
    this.documentForm.description = this.document.description
    if (this.isEditableDocumentType) {
      this.documentForm.documentType = this.document.document_type.choice
    } else {
      delete this.documentForm.documentType
    }
  }

  loadDocument(): Promise<void> {
    return axios.get(API_URLS.DOCUMENTS.RETRIEVE(this.$route.params.documentSlug)).then((response) => {
      this.document = response.data
      this.steps[1].completed = this.document.pdf_album && this.document.pdf_album.files.length > 0
      this.steps[2].completed = this.hasMappings
      this.setFormDefault()
    })
  }

  async updateDocument(): Promise<void> {
    this.isSaving = true
    await axios
      .patch(API_URLS.DOCUMENTS.PATCH(this.document.slug), decamelizeKeys(this.documentForm))
      .then((response) => {
        this.document = response.data
        this.sfSteps.nextStep()
      })
      .catch((error) => {
        throw error
      })
    this.isSaving = false
  }

  submitForm(event: Event): void {
    event.preventDefault()
    this.updateDocument()
  }

  async saveMapping(fields: IMappingField[]): Promise<void> {
    this.isSaving = true
    await axios.patch(API_URLS.DOCUMENTS.PATCH(this.document.slug), { fields }).catch((error) => {
      throw error
    })
    this.isSaving = false
  }

  finishEdit(fields: IMappingField[]): void {
    this.saveMapping(fields).then(() => {
      this.$router.push(API_URLS.DOCUMENTS.ADMINISTRATION)
    })
  }

  async created(): Promise<void> {
    this.$wait.start('load')
    await this.loadDocument()
    this.$wait.end('load')
  }
}
</script>

<style scoped lang="scss"></style>
